export default [
	"sl",
	"eu",
	"peo",
	"lzh",
	"ne",
	"te",
	"as",
	"br",
	"lb",
	"nds",
	"got",
	"mk",
	"sr",
	"ml",
	"ars",
	"ang",
	"ca-u-sd-esib",
	"de_CH",
	"is",
	"enm",
	"dum",
	"kw",
	"cy",
	"li",
	"ceb",
	"si",
	"vec",
	"frm",
	"tlh-qaak",
	"ga",
	"he",
	"pr",
	"ban",
	"kmr",
	"ca@valencia",
	"ur",
	"ro_MD",
	"mwl",
	"tokipona",
	"mt",
	"vls",
	"ta"
]