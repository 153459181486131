export enum Language {
  // English

  ENGLISH_STUPEFIED = "en_US",

  // Foreign Languages
  ARABIC = "ar",
  AZERBAIJANI = "az",
  TURKISH = "tr",
  URDU = "ur",
  RUSSIAN = "ru",
}

export interface LanguageEntry {
  /**
   * What should appear in the UI as the name for the language
   */
  display: string;

  /**
   * What emoji should be displayed
   */
  emoji: string;

  /**
   * Filename of the related language file
   */
  i18n: string;

  /**
   * Dayjs locale file if different
   */
  dayjs?: string;

  /**
   * Whether the UI should be right-to-left
   */
  rtl?: boolean;

  /**
   * Whether the language is a conlang (constructed language) or a joke
   */
  cat?: "const" | "alt";

  /**
   * Whether the language has a maintainer
   * (patched in)
   */
  verified?: boolean;

  /**
   * Whether the language is incomplete
   * (patched in)
   */
  incomplete?: boolean;
}

export const Languages: { [key in Language]: LanguageEntry } = {
  // English and "English"

  en_US: {
    display: "English (Simplified)",
    emoji: "🇺🇸",
    i18n: "en_US",
    dayjs: "en",
  },

  // Foreign languages
  ar: { display: "عربي", emoji: "🇸🇦", i18n: "ar", rtl: true, verified: true },
  az: { display: "Azərbaycan dili", emoji: "🇦🇿", i18n: "az" },
  tr: { display: "Türkçe", emoji: "🇹🇷", i18n: "tr" },
  ur: { display: "اردو", emoji: "🇵🇰", i18n: "ur" },
  ru: { display: "Русский", emoji: "🇷🇺", i18n: "ru", verified: true },
};
