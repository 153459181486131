export default [
	"en",
	"de",
	"sv",
	"hu",
	"ckb",
	"pt_BR",
	"pt_PT",
	"fr",
	"br",
	"lv",
	"ar",
	"ru",
	"uk",
	"et",
	"fil",
	"fa",
	"es",
	"es_419",
	"pl",
	"id",
	"owo",
	"el",
	"nb_NO",
	"nl",
	"ca",
	"it",
	"zh_Hant",
	"lt",
	"tokipona",
	"sk",
	"tr",
	"th",
	"ja",
	"esperanto",
	"ro",
	"zh_Hans",
	"hi",
	"bn"
]